import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyDYRCTTUc8QDVYDhPa6wYqv3HXQgGt1nD0",
    authDomain: "blocks-whitelists.firebaseapp.com",
    projectId: "blocks-whitelists",
    storageBucket: "blocks-whitelists.appspot.com",
    messagingSenderId: "394157922432",
    appId: "1:394157922432:web:27e31b77295b8fa1faee65",
    measurementId: "${config.measurementId}"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export {
    db
};