<template>
  <div id="bs-content">
    <article class="bs-article" style="margin-bottom: 0px">
        <div class="row">
          <div class="col-12">
              <label>On a journey</label>
              <h1>Beyond the moon</h1>
          </div>
          <div class="col-md-6">
              <p>We are venturing into uncharted space that extends well beyond the blockchain and the Moon. 
                We are disrupting convention by democratising the creative process in art. 
                Gamifying it and rewarding the community for their engagement.</p>
              
          </div>
          <div class="col-md-6">
            <p>Our roadmap demonstrates our long term commitment to our cause and the community. 
              After a launch in September 2021, we have succesfully delivered on our STAGE-01 and STAGE-02 roadmap. 
              Now it is time to go into STAGE-03.</p>
          </div>

        </div>
    </article>
    <article class="bs-article">
        <div class="row">
           <div class="col-12">
              <img src="../assets/img/1000Blocks-STAGE-03.png" alt="STAGE-03 Roadmap" class="bs-res-img" />
          </div>
        </div>
        <section class="row">
          <div class="col-12 notification">
            <div class="t-show">
              <br ><br ><br >
            </div>
            Regarding dates: we do not provide fixed dates. Once we feel confident that we are close to a release we will share it to our social channels. 
            Nevertheless, to get a feeling for our speed: 1000Blocks was launched early September 2021. Since then we have already released multiple major versions and are releasing on weekly basis minor updates.
          </div>
        </section>
    </article>

    <article class="bs-article" style="margin-bottom: 64px">
        <div class="row">
          <div class="col-md-8 order-2 order-md-2">
              <h2>Steer the roadmap</h2>
              <p>Big or small, we value and idea you may have! Simply voice it out on our community Telegram channel or Twitter! 
                We are constantly listening and implementing! Comming soon: community governance for our roadmap.</p>
          </div>
          <div class="col-md-4 order-1 order-md-2">
              <img src="../assets/img/ideas.png" alt="Roadmap" class="bs-res-img" />
          </div>
        </div>
    </article>

    <!--
    <article class="bs-article row">
      <div class="col-12">
        <h2>Wen marketing?</h2>
        <p>We are getting a lot of questions in the direction of "When marketin?" or "What is marketing plan?" etc.
          Although we are happy for all the interest, to prevent short term pump-and-dumps we will not share our marketing plans openly in details.
          We prefer a steady growth of both, our product and user base. 
        </p>
      </div>
    </article>
    -->

  </div>
</template>

<script>
export default {
  name: 'Roadmap',
  components: {

  },
  beforeCreate: function() {
    document.body.className = 'roadmap';
  }
}
</script>

<style scoped>
  .bs-roadmap {
    text-align: center;
  }

  .bs-roadmap-line {
    border-bottom: #1ABFB8 solid 4px;
    margin: 42px -16px 42px -16px;
    text-align: center;
  }

  .bs-roadmap-line span {
    display: inline-block;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #000;
    border: #1ABFB8 4px solid;
    margin-bottom: -22px;
  }

  .bs-roadmap-line.done span {
    background-color: #0C9690;
  }

  .bs-roadmap-line.open span {
    background-color: #000;
  }
</style>