<template>
  <div id="bs-content">

    <h1>Transparency Report</h1>
    <p>Information about audits, smart contracts, locks, liquidity.</p>

    <div class="bs-card">
      <h2>Audit</h2>
      <p>The initial audit for 1000Blocks.space was done by <a href="https://inspex.co/" target="_blank">Inspex</a>.<br />
      Full audit report:
        <a target="_blank" href="https://app.inspex.co/library/1000blocks">https://app.inspex.co/library/1000blocks</a>
      </p>
    </div>

    <div class="bs-card">
      <h2>Smart Contracts</h2>
      <p>
        <ul>
          <li>BLS Token contract: <a target="_blank" href="https://bscscan.com/address/0x34Aa9099D924F3FB2377ff20D81b235311c15346">0x34Aa9099D924F3FB2377ff20D81b235311c15346</a></li>
          <li>Space contract: <a target="_blank" href="https://bscscan.com/address/0xB2C159d81AFE012636A322F584D743919d58652c">0xB2C159d81AFE012636A322F584D743919d58652c</a></li>
          <li>Staking contract: <a target="_blank" href="https://bscscan.com/address/0x353aAb6Ad697c970dDc1378F190BbCc4cEB86C1e">0x353aAb6Ad697c970dDc1378F190BbCc4cEB86C1e</a></li>
          <li>Rewards contract: <a target="_blank" href="https://bscscan.com/address/0x198012cDfBDfb2EF58674545f7D147d928Ff5ecC">0x198012cDfBDfb2EF58674545f7D147d928Ff5ecC</a></li>
          <li>Timelock contract: <a target="_blank" href="https://bscscan.com/address/0x0Acf90dB9d91Ed7ed9D3F1544D0F25831f509B82">0x0Acf90dB9d91Ed7ed9D3F1544D0F25831f509B82</a></li>
        </ul>
      </p>
    </div>

    <div class="bs-card">
      <h2>BLS Token Liquidity</h2>
      <p>We put 12% of total tokens and 80%+ of IDO funds into liquidity to increase trust and minimize price fluctuations. 
        <br />The initial price of the token when we added liquidity for the first time was 0.033 WUSD/BLS.
      </p>
      <p>
        <a href="https://swap.wault.finance/bsc/index.html#/swap?outputCurrency=0x34Aa9099D924F3FB2377ff20D81b235311c15346" target="_blank">Wault Swap</a> (0.20% swap fees)
        <ul>
          <li>Added 106k WUSD and 106k$ worth of BLS tokens</li>
          <li><a target="_blank" href="https://bscscan.com/tx/0x7ffba8703f619dd31986ecde5bf7207b4ab7b50542646a977188e87e8359a8b1">Liquidity locked transaction</a></li>
        </ul>
      </p>
      <p>
        <a href="https://pancakeswap.finance/swap?outputCurrency=0x34Aa9099D924F3FB2377ff20D81b235311c15346" target="_blank">Pancakeswap v2</a> (0.25% swap fees)
        <ul>
          <li>Added 80k BUSD and 80k$ worth of BLS tokens</li>
          <li><a target="_blank" href="https://bscscan.com/tx/0xf9acd47dd9b0669cf3eeb76f23bd7d50c40c9e758e1c7c6b6dd2cb8ad835b9c3">Liquidity locked transaction</a></li>
        </ul>
      </p>
    </div>

    <div class="bs-card">
      <h2>Team Tokens</h2>
      <p>The Team owns 10% of all tokens.<br /> 
        All Team Tokens are locked on <a target="_blank" href="https://app.unicrypt.network/">UniCrypt</a>
        with linear vesting schedule of 6 months (<a target="_blank" href="https://bscscan.com/tx/0xdf9541a172ed47e40f6a224e658d65b4d04060a5276292c23d90f89457a9be3c">transaction)</a>.
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Transparency',
  components: {

  }
}
</script>