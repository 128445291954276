<template>
  <div id="bs-content">
    <label>Join the team</label>
    <h1>We want you</h1>
    <p>You are serios, dedicated, goofy, constructive, bit creative and most of all: you love everything related to Blockchain?
    <br />We are always searching for additions to our team!</p>

    <h2>Currently most in demand</h2>

    <div class="row">
      <div class="col-md-4">
        <div class="bs-card">
          <h3>Front-end developer</h3>
          <p>Vue.js expert. HTML/CSS guru. Canvas entusiast and optimisation freak.</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="bs-card">
          <h3>Community manager</h3>
          <p>Manage other apes. Help them onboard the rocket.</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="bs-card">
          <h3>Social media manager</h3>
          <p>Spread the word. Let the world know Space Apes are taking over.</p>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Jobs',
  components: {

  }
}
</script>

<style scoped>

</style>