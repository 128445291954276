<template>
  <div id="bs-content">
    <label>Our brand guidelines</label>
    <h2>Coming soon</h2>
    <p>A dedicated page for 1000Blocks brand guidelines is still under construction.<br /> 
      In the meantime, feel free to download our .zip brand package.</p>
    <a class="bs-button-secondary" href="https://1000blocks.space/1000Blocks-Branding.zip" target="_blank">Download 1000Blocks Branding package</a>
  </div>
</template>

<script>
export default {
  name: 'Branding',
  components: {

  }
}
</script>