<template>
  <div id="bs-content">


		<!--
		<article class="row bs-article">
			<div class="col-md-6 order-2 order-md-1">
				<label>THE BLS TOKEN</label>
				<h2>A YIELD-BEARING, DEFLATIONARY GOVERNANCE TOKEN</h2>
				<p>BLS or BlocksSpace Token is a piece of art by itself.
					It can be earned by co-creating a Space or found on the exchange.
					It can be used to earn yields and will have governance powers and premium access to future spaces.
					It is deflationary by nature and awesome in its core.
				</p>
				<router-link to="/bls-token" class="bs-button-secondary">Learn more</router-link>
			</div>
			<div class="col-md-6 order-1 order-md-2">
				<img src="../assets/img/BLS-token-3.png" alt="BLS TOKEN" class="bs-res-img" />
				<div class="row">
					<div class="col-4"><a href="https://swap.wault.finance/bsc/index.html#/swap?outputCurrency=0x34Aa9099D924F3FB2377ff20D81b235311c15346 " target="_blank" class="bs-button-secondary green">Buy on Waultswap</a></div>
					<div class="col-4"><a href="https://pancakeswap.finance/swap?outputCurrency=0x34Aa9099D924F3FB2377ff20D81b235311c15346" target="_blank" class="bs-button-secondary green">Buy on Pancakeswap</a></div>
					<div class="col-4"><a href="https://dex.guru/token/0x34aa9099d924f3fb2377ff20d81b235311c15346-bsc" target="_blank" class="bs-button-secondary">BLS Price Chart</a></div>
				</div>
			</div>
		</article>
		-->

    <article class="row bs-article">
        <div class="col-lg-4 t-hide">
            <img src="../assets/img/BLS-token.png" alt="TOKENOMICS" class="bs-res-img" />
        </div>
        <div class="col-lg-4 t-show">
            <img src="../assets/img/BLS-token-3.png" alt="TOKENOMICS" class="bs-res-img" />
        </div>
        <div class="col-lg-8">
            <label>BlockSpace TOKEN (BLS)</label>
            <h1>A YIELD-BEARING, DEFLATIONARY GOVERNANCE TOKEN</h1>
            <p>BLS or BlocksSpace Token is a piece of art by itself.
                It can be earned by co-creating a Space or found on the exchange.
                It can be used to earn yields and will have governance powers and premium access to future spaces.
                It is deflationary by nature and awesome in its core.
            </p>
            <p>Token contract:
                <a href="https://bscscan.com/address/0x34aa9099d924f3fb2377ff20d81b235311c15346">0x34aa9099d924f3fb2377ff20d81b235311c15346</a>
            </p>
            <div class="row">
                <div class="col-4"><a href="https://swap.wault.finance/bsc/index.html#/swap?outputCurrency=0x34Aa9099D924F3FB2377ff20D81b235311c15346 " target="_blank" class="bs-button-secondary bs-b-block green">Buy on Waultswap</a></div>
                <div class="col-4"><a href="https://pancakeswap.finance/swap?outputCurrency=0x34Aa9099D924F3FB2377ff20D81b235311c15346" target="_blank" class="bs-button-secondary bs-b-block green">Buy on Pancakeswap</a></div>
                <div class="col-4"><a href="https://dex.guru/token/0x34aa9099d924f3fb2377ff20d81b235311c15346-bsc" target="_blank" class="bs-button-secondary bs-b-block">BLS Price Chart</a></div>
            </div>
        </div>
    </article>

    <article class="row bs-article">
        <div class="col-md-8">
            <img src="../assets/img/hero-tokenomics.png" alt="TOKENOMICS" class="bs-res-img" />
        </div>
        <div class="col-md-4">
            <label>TOKENOMICS</label>
            <h2>A total supply of only 42.000.000 tokens </h2>
            <p>With a circulating supply (approximately) of 18.000.000 BLS is quite rare.
            In adition there is a constant steady burn rate of the token.</p>
            <p>Most of the Tokens will get distributed through rewards mining to users co-creating a NFT through our Dapp.</p>
            <router-link class="bs-button-secondary" to="/how-it-works">How it works</router-link>
        </div>
    </article>



    <div class="bs-card">
      <h2>BLS Token Liquidity</h2>
      <p>We put 12% of total tokens and 80%+ of IDO funds into liquidity to increase trust and minimize price fluctuations. 
        <br />The initial price of the token when we added liquidity for the first time was 0.033 WUSD/BLS.
      </p>
      <p>
        <a href="https://swap.wault.finance/bsc/index.html#/swap?outputCurrency=0x34Aa9099D924F3FB2377ff20D81b235311c15346" target="_blank">Wault Swap</a> (0.20% swap fees)
        <ul>
          <li>Added 106k WUSD and 106k$ worth of BLS tokens</li>
          <li><a target="_blank" href="https://bscscan.com/tx/0x7ffba8703f619dd31986ecde5bf7207b4ab7b50542646a977188e87e8359a8b1">Liquidity locked transaction</a></li>
        </ul>
      </p>
      <p>
        <a href="https://pancakeswap.finance/swap?outputCurrency=0x34Aa9099D924F3FB2377ff20D81b235311c15346" target="_blank">Pancakeswap v2</a> (0.25% swap fees)
        <ul>
          <li>Added 80k BUSD and 80k$ worth of BLS tokens</li>
          <li><a target="_blank" href="https://bscscan.com/tx/0xf9acd47dd9b0669cf3eeb76f23bd7d50c40c9e758e1c7c6b6dd2cb8ad835b9c3">Liquidity locked transaction</a></li>
        </ul>
      </p>
    </div>

    <div class="bs-card">
      <h2>Team Tokens</h2>
      <p>The Team owns 10% of all tokens.<br /> 
        All Team Tokens are locked on <a target="_blank" href="https://app.unicrypt.network/">UniCrypt</a>
        with linear vesting schedule of 6 months (<a target="_blank" href="https://bscscan.com/tx/0xdf9541a172ed47e40f6a224e658d65b4d04060a5276292c23d90f89457a9be3c">transaction)</a>.
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BLS Token',
  components: {

  }
}
</script>