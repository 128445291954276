<template>
  <div id="bs-content">

		<article class="row bs-article" style="margin-bottom: 0; border-bottom: #ff0 4px solid">
			<div class="col-12">
				<h1>1000Blocks. <br />1 Team.</h1>
        <p>The BlockSpace Team is a cross-functional team that covers every aspect of a project development and holds more than 50y of combined experience.
         Moving agile and adapting to the ever-changing crypto space is their special power.
        </p>
			</div>
    </article>

		<article class="row bs-article bs-team">

      <div class="col-md-4 bs-team-member">
        <img src="../assets/img/TM-Dev42.png" alt="Team Member" class="bs-res-img" />
        <label>OG Space Ape</label>
        <h2>IamDev42</h2>
        <a href="https://t.me/iamdev42" target="_blank">Telegram: @iamdev42</a>
        <p>He is Dev42. 
          He builds smart contracts to take apes to space. 
          Far above the AMZN, MS, GOOGL clouds. 
          Secures he everything. 
          No bot can snipe. 
          Was in crypto before your mama was born.</p>
      </div>

      <div class="col-md-4 bs-team-member">
        <img src="../assets/img/TM-Funky.png" alt="Team Member" class="bs-res-img" />
        <label>OG Space Ape</label>
        <h2>Funky</h2>
        <a href="#none" target="_blank">Telegram: none</a>
        <p>He likes it funky. So he drifts. 
          Very much, drifty wow. When no drift, he backend dev. 
          When no backend dev he smart contract, then he drifts again. 
          Then he optimizes. When he sleeps he dreams code. 
          It is ok. We like it funky too.</p>
      </div>

      <div class="col-md-4 bs-team-member">
        <img src="../assets/img/TM-MoonBaboon.png" alt="Team Member" class="bs-res-img" />
        <label>OG Space Ape</label>
        <h2>MoonBaboon</h2>
        <a href="https://t.me/moonbaboon" target="_blank">Telegram: @moonbaboon</a>
        <p>A little bit of frontend, then a little bit of backend and finally he connected to the contracts. 
          A decade of experience and he still knows nothing.</p>
      </div>

      <div class="col-md-4 bs-team-member">
        <img src="../assets/img/TM-KGX.png" alt="Team Member" class="bs-res-img" />
        <label>OG Space Ape</label>
        <h2>KGX</h2>
        <a href="https://t.me/kgx01" target="_blank">Telegram: @kgx01</a>
        <p>To design or not to design, that is here the question. 
          U and I and X lead with outer space ideas. He knows the business. 
          Strategy. And code.  But when he code, he breaks. 
          Hated by devs, loved by lady apes.</p>
      </div>

      <div class="col-md-4 bs-team-member">
        <img src="../assets/img/TM-DLin.png" alt="Team Member" class="bs-res-img" />
        <label>OG Space Ape</label>
        <h2>DLin</h2>
        <a href="https://t.me/dejlin" target="_blank">Telegram: @dejlin</a>
        <p>The OGG. Certified to be precise. 
          He mostly knows what he's talking about, other stuff he leaves to devs. 
          Ask him anything. He likes to send cryptos to random wallets.</p> 
      </div>

      <div class="col-md-4 bs-team-member">
        <img src="../assets/img/TM-BeastHash.png" alt="Team Member" class="bs-res-img" />
        <label>Smart Space Ape</label>
        <h2>BeastHash</h2>
        <a href="https://t.me/BeastHash" target="_blank">Telegram: @BeastHash</a>
        <p>By day he leads. By night he's Beast. 
          Hashing coins on the chain his thing. 
          He likes to find new people. Not to eat. 
          To become friends. Partners? 
          We would like to think.</p>
      </div>

      <div class="col-md-4 bs-team-member">
        <img src="../assets/img/TM-Job1n.png" alt="Team Member" class="bs-res-img" />
        <label>Social Space Ape</label>
        <h2>Job1n</h2>
        <a href="https://t.me/Job1n" target="_blank">Telegram: @Job1n</a> 
        <p>This ape faster than cheetah. 
          Daily investing in himself and crypto. 
          Can program machines and likes to make friends. 
          Everyone’s wife loves him. We do too.
        </p>
      </div>

      <div class="col-md-4 bs-team-member">
        <img src="../assets/img/TM-Wired.png" alt="Team Member" class="bs-res-img" />
        <label>Gifted Space Ape</label>
        <h2>Wired</h2>
        <a href="https://t.me/Wiredtogether" target="_blank">Telegram: @Wiredtogether</a> 
        <p>The only ape born in snow. Knows 1 + 1 in binary.
        Chooses to promote bananas instead. 
        His work is recommended by 4 out of 5 people that recommend things. 
        We HIGHLY recommend him too
        </p>
      </div>

      <div class="col-md-4 bs-team-member">
        <img src="../assets/img/TM-Nate.png" alt="Team Member" class="bs-res-img" />
        <label>Dev Space Ape</label>
        <h2>Nate</h2>
        <a href="https://t.me/thenatex" target="_blank">Telegram: @thenatex</a> 
        <p>Curious Ape, started bucket sorting socks pre-dev. 
          Now wont stop opening consoles for errors. 
          A few years later counting from zero. 
          Ape now Coffee Ape post-dev.
        </p>
      </div>

    </article>

  </div>
</template>

With Design Thinking approach applied in projects, we managed to achieve a desired outcome within a short timeframe 


<script>
export default {
  name: 'Team',
  components: {

  }
}
</script>

<style scoped>
  .bs-team-member a {
    font-size: 0.8em;
    text-decoration: none;
    color: #0ff;
  }

  .bs-team-member a:hover {
    text-decoration: underline;
  }
</style>