<template>
    <header id="bs-header">
        <div class="container">
            <router-link id="bs-page-logo" to="/"><img src="../assets/img/1000BS-logo.png" alt="1000Blocks.space" /></router-link>

            <div id="bs-page-nav" class="m-hide">
                <router-link class="tab" to="/how-it-works">How it works</router-link>
                <router-link class="tab" to="/bls-token">BLS Token</router-link>
                <!--  <router-link class="tab" to="/spaceapes-nft-collection">Space Apes NFTs</router-link> -->
                <a href="https://spaceapes.1000blocks.space" class="tab">SpaceApes NFTs</a> 
                <router-link class="tab" to="/roadmap">Roadmap</router-link>
                <router-link class="tab" to="/contact">Get in touch</router-link>
            </div>      
             
            <a id="bs-page-cta" href="https://app.1000blocks.space" class="bs-cta m-hide">LAUNCH DAPP</a>

            <router-link v-if="$route.name != 'PageSelector'" id="bs-page-cta" class="bs-button-secondary m-show" to="/page-selector">MENU</router-link>
            <router-link v-if="$route.name == 'PageSelector'" id="bs-page-cta" class="bs-button-secondary m-show" to="/">X</router-link>
        </div>
    </header>
</template>





<script>
export default {
  name: 'PageHeader',
  props: {
        title: {
            default: "-",
            type: String
        },
        backLink: {
            default: "",
            type: String
        }
    }
}
</script>

<style scoped lang="scss">
    #bs-header {
        background-color: #000;
        padding: 0 8px 0 8px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
    }

    #bs-page-logo {
        float: left;
        margin-right: 16px;
    }

    #bs-page-logo img {
        height: 30px;
        width: auto;
        margin: 8px;
        padding: 0;
    }

    #bs-page-nav {
        float: left;
        margin: 0;
        padding: 0;
    }

    #bs-page-nav a {
        display: inline-block;
        font-size: 14px;
        line-height: 1em;
    }

    #bs-page-nav .tab {
        text-transform: uppercase;
        padding: 16px;
        padding-bottom: 14px;
        margin: 0;
        text-decoration: none;
        border-bottom: solid 2px #000;
    }

    #bs-page-nav .tab:hover,
    #bs-page-nav .tab.router-link-active {
        color: #fff;
        border-bottom: solid 2px #ff0 !important;
    }

    #bs-page-cta {
        float: right;
        margin: 8px 0 8px 0;
    }



</style>