<template>
  <div id="bs-content">
    <router-link to="/whitelistme/spaceapes-drop-01">Space Apes Whitelist</router-link>
  </div>
</template>

<script>
export default {
  name: 'NFT Collection',
  components: {

  }
}
</script>