<template>
    <div id="bs-footer">

        <div class="container" style="margin-bottom:42px">
            <a href="https://app.1000blocks.space/mint/overview?mint=true" target="_blank">
                <img src="../assets/img/1000Blocks-SpaceApes-Banner-Mint_1440x90.png" alt="Space Apes by 1000Blocks" style="width:100%; height: auto" />
            </a>
        </div>

        <footer id="bs-page-footer-mobile" class="m-show">
            <a href="https://app.1000blocks.space" class="bs-cta bs-b-block">LAUNCH DAPP</a>
        </footer>
        <div class="container">
            <div class="row">
                <div class="col-md-3 m-hide">
                    <section style="margin-bottom: 42px">
                    <label style="margin-bottom: 8px; display: block">The 1000Blocks project</label>
                        <div id="bs-footer-nav">
                            <!-- <router-link to="/about">Background</router-link> -->
                            <router-link to="/team">Project team</router-link>
                            <router-link to="/jobs">Job board</router-link>
                            <router-link to="/transparency">Transparency report</router-link>
                            <router-link to="/branding">Branding</router-link>
                        </div>
                    </section>
                    <section>
                        <label style="margin-bottom: 8px; display: block">Made by</label>
                        <a href="https://blockspace.solutions" target="_blank">
                            <img src="../assets/img/blockspace.solutions.png" alt="BlockSpace Solutions" style="width:50%; height: auto" />
                        </a>
                    </section>
                </div>
                <div class="col-md-9">
                    <label style="margin-bottom: 8px; display: block">Official project channels</label>
                    <div class="row">
                        <div class="col-6 col-md-4">
                            <section class="bs-social">
                                News and announcements
                                <!--
                                <a href="#" class="bs-social-link">
                                    <img src="../assets/img/Social-1000Blocks-Telegram-News.png" alt="Telegram News" />
                                    <span>Telegram: <br />@announcement1000blocks</span>
                                </a>
                                -->
                                <a href="https://twitter.com/1000Blocks" target="_blank" class="bs-social-link">
                                    <img src="../assets/img/Social-1000Blocks-Twitter.png" alt="Twitter" />
                                    <span class="m-hide">Twitter: <br />
                                    @1000Blocks</span>
                                </a>
                                <a href="https://www.reddit.com/r/1000Blocks/" target="_blank" class="bs-social-link">
                                    <img src="../assets/img/Social-1000Blocks-Reddit.png" alt="Twitter" />
                                    <span class="m-hide">Reddit: <br />
                                    @1000Blocks</span>
                                </a>
                                <a href="https://www.facebook.com/1000blocks.space" target="_blank" class="bs-social-link">
                                    <img src="../assets/img/Social-1000Blocks-Facebook.png" alt="Facebook" />
                                    <span class="m-hide">Facebook: <br />
                                    @1000blocks.space</span>
                                </a>
                            </section>
                        </div>
                        <div class="col-6 col-md-4">
                            <section class="bs-social">
                                Community discussions
                                <a href="https://t.me/thousandblocks" target="_blank" class="bs-social-link">
                                    <img src="../assets/img/Social-1000Blocks-Telegram-Community.png" alt="Telegram" />
                                    <span class="m-hide">Telegram: <br />@thousandblocks</span>
                                </a>
                                <a href="https://discord.gg/cqvX2ZRvNK" target="_blank" class="bs-social-link">
                                    <img src="../assets/img/Social-1000Blocks-Discord.png" alt="Discord" />
                                    <span class="m-hide">Discord: <br />1000Blocks</span>
                                </a>
                                <!--
                                <a href="" class="bs-social-link">
                                    <img src="../assets/img/Social-1000Blocks-Telegram-BLS.png" alt="Telegram Community" />
                                    <span>Telegram: <br />@thousandblocks_price</span>
                                </a>
                                -->
                            </section>
                        </div>
                        <div class="col-6 col-md-4">
                            <section class="bs-social">
                                NFT Distribution
                                <a href="https://opensea.io/collection/1000blocks-spaces" target=""  class="bs-social-link">
                                    <img src="../assets/img/Social-1000Blocks-OpenSea.png" alt="OpenSea" />
                                    <span class="m-hide">OpenSea: <br />
                                    1000blocks-spaces</span>
                                </a>
                            </section>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 col-md-4">
                            <section>
                                Sneak peek
                                <a href="https://www.instagram.com/1000blocks.space/" target="_blank" class="bs-social-link">
                                    <img src="../assets/img/Social-1000Blocks-Instagram.png" alt="Instagram" />
                                    <span class="m-hide">Instagram: <br />
                                    @1000blocks.space</span>
                                </a>
                                <a href="https://www.tiktok.com/@1000blocks" target="_blank" class="bs-social-link">
                                    <img src="../assets/img/Social-1000Blocks-TikTok.png" alt="Instagram" />
                                    <span class="m-hide">TikTok: <br />
                                    @1000blocks</span>
                                </a>
                            </section>
                        </div>
                        <div class="col-6 col-md-4">
                            <section class="bs-social">
                                How to and tutorials
                                <a href="https://www.youtube.com/channel/UCa0SbrRAAd6re9QKfQ9sKCw" target="_blank" class="bs-social-link">
                                    <img src="../assets/img/Social-1000Blocks-Youtube.png" alt="YouTube" />
                                    <span class="m-hide">YouTube: <br />
                                    1000Blocks Space</span>
                                </a>
                            </section>
                        </div>
                        <div class="col-6 col-md-4">
                            <section class="bs-social">
                                Code
                                <a href="https://github.com/1000Blocks-space" target="_blank" class="bs-social-link">
                                    <img src="../assets/img/Social-1000Blocks-GitHub.png" alt="GitHub" />
                                    <span class="m-hide">GitHub: <br />
                                    1000Blocks-space</span>
                                </a>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'PageFooter'
}
</script>

<style scoped lang="scss">
    #bs-footer {
        background-color: #00161C;
        padding: 24px 0 96px 0;
    }

    #bs-footer-nav {
        //border-top: #999 solid 1px;
    }

    #bs-footer-nav a {
        display: block;
        text-decoration: none;
        padding: 8px 0 8px 0;
    }

    #bs-footer-nav a:hover,
    #bs-footer-nav a.router-link-exact-active {
        border-left: #ff0 solid 2px;
        padding-left: 8px;
        color: #fff;
    }

    #bs-page-footer-mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #00161C;
        z-index: 10000000;
    }

    #bs-page-footer-mobile a {
        margin: 16px 32px 16px 32px;
    }
</style>