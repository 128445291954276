<template>
  <div id="bs-content">
    <label>Contact us</label>
    <h1>We are always here to listen</h1>
    <p>We are always happy to listen to any idea and/or feedback how to make 1000Blocks better or how we could partner up.</p>
    <p>For ideas/feedback you can always join our 
      <a href="https://t.me/thousandblocks" target="_blank">Telegram channel</a> or 
      <a href="https://discord.gg/cqvX2ZRvNK" target="_blank">Discord</a>.<br /> 
      The team is always there, and we try to follow up in a timely matter.
    </p>
    <p>In case you have proposal for a collaboration, please fill out or partnership request form.</p>

    <a class="bs-button-secondary" href="https://forms.gle/iFKK1iyndFr6NHF56" target="_blank">Partnership request</a>
  </div>
</template>

<script>
export default {
  name: 'Contact us',
  components: {

  }
}
</script>