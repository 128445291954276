<template>
  <div id="bs-content">

  </div>
</template>

<script>
export default {
  name: 'About',
  components: {

  }
}
</script>