import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAnalytics from 'vue-analytics'

createApp(App).use(store).use(router).mount('#app')

App.use(VueAnalytics, {
    id: 'G-Z0FN5CK79N',
    router
});