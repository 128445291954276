<template>
  <div id="bs-content" style="max-width:900px; margin: 42px auto 42px auto">
    <div v-if="$route.params.id">

      <div v-if="$route.params.id == 'spaceapes-drop-01-old'">
        <label>Whitelist</label>
        <h1>Space Apes by 1000Blocks</h1>
        <div style="margin-bottom: 128px">
          <div v-if="loading">
              <p>Loading</p>
          </div>
          <div v-else>
              <div v-if="!listed">
                  
                      <p>The first <a href="https://spaceapes.1000blocks.space/">Smart NFT collection</a> by <a href="https://1000blocks.space">1000Blocks</a>. <br />
                      Join the whitelist to have priority access for the mint.</p>
                      <div class="input-block">
                          <label for="wallet-address">Binance Smart Chain wallet address</label>
                          <input id="wallet-address" v-model="address" class="input-large" type="text">
                      </div>
                      <button style="min-width:120px" class="bs-cta" @click="whitelist(address)">Add</button>

              </div>
              <div v-else>
                  <div v-if="!didExtra">

                      <div v-if="!walletExists" class="yellow-notification">
                          <b>{{this.address}}</b> was succesfully added to the Whitelist.<br />
                          Keep an eye on <a href="https://spaceapes.1000blocks.space">
                          spaceapes.1000blocks.space</a> for more info about the mint.<br /><br />
                          <a @click="resetForm()" class="bs-button-third" style="margin-right: 8px">Add another Wallet</a>
                          <a href="https://1000blocks.space" class="bs-button-third">About 1000Blocks.space</a>
                      </div>

                      <div v-else class="yellow-notification">
                          <b>Welcome back!</b><br />
                          Seems the address <b>{{this.address}}</b> is already on the whitelist.<br /> 
                          Below you can find options on how you can increase your chances for a Legendary Space Ape!<br /><br />
                          <a @click="resetForm()" class="bs-button-third" style="margin-right: 8px">Add another Wallet</a>
                          <a href="https://1000blocks.space" class="bs-button-third">About 1000Blocks.space</a>
                      </div>

                      <div class="bs-card" style="margin-bottom: 42px !important">
                        <h3 style="margin-bottom: 28px">Increase your chances to get a Legendary SpaceApe NFT!</h3>

                        <div class="row bs-article">
                          <div class="col-md-3">
                             <img src="../assets/img/the-legendary.gif" alt="legendary-apes" class="bs-res-img" />
                          </div>
                          <div class="col-md-9">
                            <img src="https://spaceapes.1000blocks.space/img/SpaceApes-by-1000Blocks.svg" alt="Space Apes" style="width:160px; height: auto" />
                            <p>Our biggest and longest holders of BLS tokens (staked in Vault or Farm) will have increased chances
                                to get one of our Legendary SpaceApes NFT!</p>

                              <p>To (further) increase your chances, invite a friend and join our community!</p>
                          </div>
                        </div>

                        <div class="row bs-article">
                          <div class="col-md-2">
                             <img src="../assets/img/element_invite.svg" alt="invite" class="bs-res-img small" />
                          </div>
                          <div class="col-md-10">
                            <h4>Step 1: Invite a friend!</h4>
                              <div class="input-block">
                                  <code>
                                    {{this.refLink}}
                                  </code>
                              </div>
                              <p>Each user who signs up for the whitelist using the link above will reward you one extra "tickets" for a Legendary SpaceApe NFT! 
                                The user will also recive an extra ticket. It's a win:win! Note: each user can collect max 10 tickets from referrals.
                                </p>
                          </div>
                        </div>

                        <div class="row bs-article">
                          <div class="col-md-2">
                             <img src="../assets/img/element_community.svg" alt="sommunity" class="bs-res-img small" />
                          </div>
                          <div class="col-md-10">
                            <h4>Step 2: Join our community on Twitter, Telegram and Discord</h4>

                              <div class="row">
                                <div class="col-md-4">
                                  <a href="https://twitter.com/1000Blocks" target="_blank" class="bs-social-link">
                                      <img src="../assets/img/Social-1000Blocks-Twitter.png" alt="Twitter" />
                                      <span class="m-hide">Twitter: <br />
                                      @1000Blocks</span>
                                  </a>
                                   <input id="tw-handle" v-model="twHandle" placeholder="@your_handle" class="input-large" style="max-width: 240px" type="text">
                                </div>
                                <div class="col-md-4">
                                  <a href="https://t.me/thousandblocks" target="_blank" class="bs-social-link">
                                      <img src="../assets/img/Social-1000Blocks-Telegram-Community.png" alt="Telegram" />
                                      <span class="m-hide">Telegram: <br />@thousandblocks</span>
                                  </a>
                                  <input id="tg-handle" v-model="tgHandle" placeholder="@your_handle" class="input-large" style="max-width: 240px" type="text">
                                </div>
                                <div class="col-md-4">
                                  <a href="https://discord.gg/cqvX2ZRvNK" target="_blank" class="bs-social-link">
                                      <img src="../assets/img/Social-1000Blocks-Discord.png" alt="Discord" />
                                      <span class="m-hide">Discord: <br />1000Blocks</span>
                                  </a>
                                  <input id="dis-handle" v-model="disHandle" placeholder="your#user" class="input-large" style="max-width: 240px" type="text">
                                </div>
                              </div>
                              <p>Join our community and tell us your handle/username. 
                                Before the mint we will cross-check. Being part of a channel gives you 2 additional tickets! Max 6 tickets.</p>
                              <button style="width: 100%" class="bs-button-third" @click="updateEntry(address,[twHandle,tgHandle,disHandle])">Submit</button>
                          </div>
                        </div>  

                      </div>

                       <a @click="resetForm()" class="bs-cta  bs-b-block">Add another Wallet</a>
                       <a href="https://1000blocks.space" class="bs-cta  bs-b-block">About 1000Blocks.space</a>
                        <br /><br /><br />
                  </div>
                  <div v-else>
                      
                      <div class="yellow-notification">
                          <b>Awesome,</b> you just increased your chances for a Legendary SpaceApe!<br />
                          Keep an eye on <a href="https://spaceapes.1000blocks.space">
                          spaceapes.1000blocks.space</a> for more info about mint.<br /><br />
                          <button @click="resetForm()" class="bs-button-third">Add another Wallet</button>
                      </div>

                  </div>
              </div>
          </div>
        </div>
      </div>
      <div v-else-if="$route.params.id == 'spaceapes-drop-01.status'">
          <label>Whitelist Status</label>
          <h1>Space Apes by 1000Blocks</h1>
          <div class="bs-card">
            <p>Total entries: {{this.listCounter}}</p>
            <p>Entries from referral: {{this.listCounterRef}}</p>
          </div>
      </div>
      <div v-else>
        <h1>Whitelist closed</h1>
      </div>
    </div>
    <div v-else>
      <h1>Available Whitelists</h1>
      <p><router-link to="/whitelistme/spaceapes-drop-01">Whitelist for SpaceApes</router-link></p>
    </div>
  </div>
</template>

<script>
//import { VueRecaptcha } from 'vue-recaptcha';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, increment } from "firebase/firestore";
//import { collection, getDocs, where, query } from "firebase/firestore";
export default {
  name: 'Whitelist',
    components: {
      //VueRecaptcha
    },
    data(){
        return{
            list: "",
            address: "",
            tgHandle: "",
            twHandle: "",
            disHandle: "",
            loading: false,
            listed: false,
            didExtra: false,
            referral: false,
            refCode: false,
            refLink: false,
            walletExists: false,
            listCounter: 0,
            listCounterRef: 0
        }
    },
    methods: {
        resetForm(){
            this.address = "";
            this.listed = false;
            this.walletExists = false;
            this.didExtra = false;
            this.setRefParams();
        },

        setRefLink(refCode){
           this.refLink = "https://1000blocks.space/whitelistme/"+this.list+"?ref=" + refCode;
        },

        setStatus(){
          var mode = this.list.split(".");
          console.log(mode[1]);
          if(mode[1] == "status"){

            getDoc(doc(db, mode[0]+"-meta", "counters")).then(docSnap => {
                if (docSnap.exists()) {
                    var data = docSnap.data();
                    this.listCounter = data.total;
                    this.listCounterRef = data.fromRef;
                }
            });
            
          }
        },

        setRefParams(){
            if(this.$route.params.id){
              //this.$refs.recaptcha.execute();
              this.list = this.$route.params.id;
              if(this.$route.query.ref){
                  this.referral = this.$route.query.ref;
              }
              this.refCode = Date.now()+Math.random().toString(32).substring(2,15);
              this.setRefLink(this.refCode);
            }  
        },

        whitelist(address){
            this.loading = true;
            this.address = address;

            getDoc(doc(db, this.list, address)).then(docSnap => {
                if (docSnap.exists()) {
                    var user = docSnap.data();
                    this.walletExists = true;
                    this.loading = false;
                    this.address = user.wallet;
                    this.myRef = user.myRef;
                    this.refCode = user.myRef;
                    this.tgHandle = user.telegram;
                    this.twHandle = user.twitter;
                    this.disHandle = user.discord;
                    this.listed = true;
                    this.setRefLink(this.refCode);
                    console.log("Document data:", docSnap.data());
                } else {
                        this.walletExists = false;
                        setDoc(doc(db, this.list, address), {
                            date: Date.now(),
                            refBy: this.referral,
                            myRef: this.refCode,
                            wallet: this.address  
                        }).then(() => {
                            this.loading = false;
                            this.listed = true;
                            if(this.referral){
                              updateDoc(doc(db, this.list+"-meta", "counters"), {
                                  total: increment(1),
                                  fromRef: increment(1)
                                });
                            } else {
                              updateDoc(doc(db, this.list+"-meta", "counters"), {
                                  total: increment(1)
                                });
                            }

                        })
                        .catch((error) => {
                            console.error("Error writing document: ", error);
                        });
                }
            });
        },
        
        updateEntry(address,handles){
            this.loading = true;
            setDoc(doc(db, this.list, address), {
                date: Date.now(),
                refBy: this.referral,
                myRef: this.refCode,  
                twitter: handles[0],
                telegram: handles[1],
                discord: handles[2],
                wallet: this.address  
            }).then(() => {
                this.loading = false;
                this.listed = true;
                this.didExtra = true;
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });
        }
    },
    mounted() {
        this.setRefParams();
        this.setStatus();
    }
}

</script>
<style scoped>
  .bs-res-img.small {
    width: 80%;
    margin: 10%;
  }
  .bs-article {
    margin-bottom: 64px;
  }
</style>