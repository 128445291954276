import { createRouter } from 'vue-router'
import { createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import How from '../views/How.vue'
import BLS from '../views/BLS.vue'
import NFTs from '../views/NFTs.vue'
import Whitelist from '../views/Whitelist.vue'
import Roadmap from '../views/Roadmap.vue'
import Contact from '../views/Contact.vue'
import Team from '../views/Team.vue'
import Jobs from '../views/Jobs.vue'
import Branding from '../views/Branding.vue'
import Transparency from '../views/Transparency.vue'
import Switcher from '../views/PageSelector.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/team',
    name: 'Team',
    component: Team
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs
  },
  {
    path: '/branding',
    name: 'Branding',
    component: Branding
  },
  {
    path: '/transparency',
    name: 'Transparency',
    component: Transparency
  },
  {
    path: '/how-it-works',
    name: 'How it works',
    component: How
  },
  {
    path: '/bls-token',
    name: 'BLS Token',
    component: BLS
  },
  {
    path: '/roadmap',
    name: 'Roadmap',
    component: Roadmap
  },
  {
    path: '/spaceapes-nft-collection',
    name: 'Space Apes NFT Collection',
    component: NFTs
  },
  {
    path: '/whitelistme/:id',
    name: 'Whitelist',
    component: Whitelist
  },
  {
    path: '/page-selector',
    name: 'PageSelector',
    component: Switcher
  },
  {
    path: '/contact',
    name: 'Contact us',
    component: Contact
  },
]

const router = createRouter({
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
  history: createWebHistory(),
  routes
})

export default router
