<template>
  <div class="bootstrap-wrapper">
    <PageHeader v-if="$route.name != 'Whitelist'" />
    <div id="bs-container" class="">        
      <div class="container">
        <router-view/>
      </div>
    </div>
    <PageFooter v-if="$route.name != ('PageSelector' && 'Whitelist')"  />
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import PageFooter from '@/components/PageFooter.vue'

export default {
  name: 'App',
  components: {
    PageHeader,
    PageFooter
  }
}
</script>

<style lang="scss">
  ////// normalize
  article,aside,details,figcaption,figure,footer,header,hgroup,main,nav,section,
  summary{display:block;}audio,canvas,video{display:inline-block;}
  audio:not([controls]){display:none;height:0;}[hidden]{display:none;}
  html{font-family:sans-serif;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%;}
  body{margin:0;}a:focus{outline:thin dotted;}
  a:active,a:hover{outline:0;}
  h1{font-size:2em;margin:0.67em 0;}
  abbr[title]{border-bottom:1px dotted;}
  b,strong{font-weight:bold;}
  dfn{font-style:italic;}
  hr{-moz-box-sizing:content-box;box-sizing:content-box;height:0;}
  mark{background:#ff0;color:#000;}
  code,kbd,pre,samp{font-family:monospace,serif;font-size:1em;}
  pre{white-space:pre-wrap;}q{quotes:"\201C" "\201D" "\2018" "\2019";}
  small{font-size:80%;}
  sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline;}
  sup{top:-0.5em;}sub{bottom:-0.25em;}img{border:0;}svg:not(:root){overflow:hidden;}
  figure{margin:0;}
  fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:0.35em 0.625em 0.75em;}
  legend{border:0;padding:0;}
  button,input,select,textarea{font-family:inherit;font-size:100%;margin:0;}
  button,input{line-height:normal;}button,select{text-transform:none;}
  button,html input[type="button"],input[type="reset"],input[type="submit"]{-webkit-appearance:button;cursor:pointer;}
  button[disabled],html input[disabled]{cursor:default;}input[type="checkbox"],input[type="radio"]{box-sizing:border-box;padding:0;}
  input[type="search"]{-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;}
  input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration{-webkit-appearance:none;}
  button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}
  textarea{overflow:auto;vertical-align:top;}table{border-collapse:collapse;border-spacing:0;}
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  ////// done normalizing

  ///// custom CSS

  @import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@400;500;700&display=swap');

  :root {
    font-size: 15px;
  }

  body {
    color: #1ABFB8;	
    font-family: 'Fira Mono', monospace;
    background-color: #00212A;
    background-image: url("./assets/img/space.jpg");
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    line-height: 1.5em;
  }

  body.roadmap {
    background-image: none;
  }

  html, body {
    height: 100%;
  }

  label {
    color: #ff0;
    text-transform: uppercase;
  }

  label.icon-label {
    font-size: 0.8em;
    text-align: center;
    display: block;
  }

  h1, h2, h3, h4 {
    margin-top: 0;
    color: #fff;
    margin-bottom: 0.3em;
    text-transform: uppercase;
    line-height: 1.2em;
  }

  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.5em;
  }

  h4 {
    font-size: 1em;
    color: #ff0;
  }

  p {
    color: #ccc;
    line-height: 1.5em;
    margin-bottom: 2em;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  li {
    margin-bottom: 1em;
  }


  .bs-article {
    margin-bottom: 128px;
  }

  #bs-content {
    margin-top: 96px;
    min-height: 640px;
  }

  .bs-note {
    background-color: #D06CC1;
    color: #fff;
    padding: 1em;
    text-align: center;
  }

  .bs-cta {
    background-color: #ff0;
    color: #000;
    padding: 8px 16px 8px 16px;
    text-decoration: none !important;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
  }

  .bs-cta:hover {
    background-color: #fff;
  }

  .bs-button-secondary {
    color: #fff;
    border: #fff solid 1px;
    padding: 8px 16px 8px 16px;
    text-decoration: none !important;
    display: inline-block;
    text-transform: uppercase;
  }

  .bs-button-third {
    color: #fff !important;
    border: #444 solid 1px;
    background-color: #111;
    padding: 8px 16px 8px 16px;
    text-decoration: none !important;
    display: inline-block;
    text-transform: uppercase;
  }

  .bs-button-secondary:hover {
    color: #1ABFB8;
    border: #1ABFB8 solid 1px;
  }

  .bs-b-block {
    display: block;
    text-align: center;
    margin-bottom: 12px;
    text-decoration: none !important;
  }

  .bs-button-point {
    display: block;
    text-align: left;
    padding: 8px 16px 8px 16px;
    color: #fff;
    text-decoration: none !important;
  }

  .green {
    color: #0f0;
  }

  .bs-button-point:hover {
    color: #1ABFB8;
  }

  .mb-1 {
    margin-bottom: 1em;
  }

  .mb-4 {
    margin-bottom: 4em;
  }

  .bs-res-img {
    width: 100%;
    height: auto;
  }

  .bs-card {
    margin: 2em 0 2em 0;
    padding: 1em;
    background-color: #000;
  }

  .input-block label,
  .input-block input {
    display: block;
  }

  .m-show {
    display: none;
  }

  .t-show {
    display: none;
  }

  .input-large {
    background-color: #00151B;
    font-size: 18px;
    padding: 8px;
    border: #999 solid 1px;
    color: #ccc;
    width: 100%;
    max-width: 520px;
    margin: 8px 0 8px 0;
  }

  .yellow-notification {
    background-color: #ff0;
    border: 1px solid #990;
    color: #000;
    padding: 16px;
    margin-bottom: 42px;
  }

  .yellow-notification a{
    color:#000;
  }

  .bs-social {
        margin-top: 8px;
        margin-bottom: 56px;
  }

  .bs-social-link {
      text-decoration: none;
      margin: 24px 0 24px 0;
      display: flex;
      justify-content: left;
      align-items: center;
      font-size: 0.8em;
      line-height: 1.6em;
  }

  .bs-social-link:hover {
      color: #fff;
  }

  .bs-social-link img {
      float: left;
      height: 56px;
      width: auto;
      margin-right: 16px;
  }

  @media screen and (max-width: 1160px) {
    .t-hide {
      display: none !important;
    }

    .t-show {
      display: block;
    }
  }

  @media screen and (max-width: 800px) {
    .m-hide {
      display: none !important;
    }

    .m-show {
      display: inline-block;
    }

    .bs-listed-on {
      display: block;
      text-align: center
    }
  }
  @import './assets/css/bootstrap-grid.min.css';
</style>
